import { useHydrated } from './use-hydrated';

// https://github.com/sergiodxa/remix-utils/blob/main/src/react/client-only.tsx
export function ClientOnly({
    children,
    fallback = null,
}: {
    children(): React.ReactNode;
    fallback?: React.ReactNode;
}) {
    return useHydrated() ? <>{children()}</> : <>{fallback}</>;
}
